import BackgroundImage from "./background.webp";
import { View, StyleSheet, Text } from "react-native";
import { cssClass } from "utils/css";
import "./index.css";
import { LinkButton } from "../common/Button";
import ArrowDownIcon from "./arrow-down.svg";
import { Safe } from "../common/Safe";
import { primaryColor } from "../Constants";
import { Link } from "react-scroll";
import { Image } from "expo-image";

export const Main = () => {
  return (
    <View id="main" style={[cssClass("main-container"), styles.container]}>
      <View style={styles.backgroundContainer}>
        <Image
          style={styles.background}
          placeholder={BackgroundImage}
          source={BackgroundImage}
          alt="Жёлтое такси с рекламой на фоне ночного Минска с ярко освещенными зданиями и движущимся транспортом"
        />
      </View>
      <View style={styles.arrowDown}>
        <ArrowDownIcon style={cssClass("main-arrow-down")} />
      </View>
      <View style={styles.scrollDownContainer}>
        <Link
          style={styles.scrollDown}
          aria-label="Далее"
          href="#about"
          to="about"
          containerId="home-scroll"
          smooth={true}
        />
      </View>
      <Safe style={[cssClass("main-rows"), styles.rows]}>
        <View style={styles.titleContainer}>
          <View style={[cssClass("main-title-border"), styles.titleBorder]} />
          <h1 className="app-main-title" style={styles.title}>
            Реклама
            <br />в такси
          </h1>
        </View>
        <View
          style={[
            cssClass("main-description-container"),
            styles.descriptionContainer,
          ]}
        >
          <Text style={[cssClass("main-description"), styles.description]}>
            За копейки о вас узнают миллионы.{"\n"}Авто «под ключ» - 90 BYN /
            мес.
          </Text>
          <Text style={[cssClass("main-description2"), styles.description2]}>
            Стоимость 1 контакта с рекламой - 5 копеек.
          </Text>
          <LinkButton to="pricing">Узнать больше</LinkButton>
        </View>
      </Safe>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-end",
    padding: 32,
  },
  backgroundContainer: {
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    opacity: 0.2,
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
  },
  background: {
    minWidth: "100%",
    minHeight: "100%",
    aspectRatio: 1,
  },
  rows: {
    flexDirection: "row",
    alignItems: "flex-end",
    gap: 16,
  },
  titleContainer: {
    flex: 1,
    paddingStart: 15 + 16,
  },
  titleBorder: {
    backgroundColor: primaryColor,
    width: 15,
    position: "absolute",
    bottom: 18,
    top: 22,
    left: 0,
  },
  title: {
    fontSize: 85,
    fontWeight: "700",
    color: "white",
    textTransform: "uppercase",
    margin: 0,
  },
  descriptionContainer: {
    gap: 16,
    alignItems: "flex-end",
  },
  description: {
    color: "white",
    fontSize: 20,
    textAlign: "right",
  },
  description2: {
    color: "white",
    fontWeight: "300",
    textAlign: "right",
    marginBottom: 16,
  },
  arrowDown: {
    pointerEvents: "none",
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 32,
    alignItems: "center",
    zIndex: -1,
  },
  scrollDownContainer: {
    alignSelf: "center",
    alignItems: "center",
    position: "absolute",
    pointerEvents: "box-none",
    bottom: 0,
    zIndex: 1,
  },
  scrollDown: {
    width: 80,
    height: 80,
  },
});
