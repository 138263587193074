import { StyleSheet, Text, View } from "react-native";
import { Title } from "../common/Title";
import { Safe } from "../common/Safe";
import "./index.css";
import { darkColor, secondaryColor } from "../Constants";
import { LinkButton } from "../common/Button";
import { cssClass } from "utils/css";
import { Section } from "../common/Section";
import { Image } from "expo-image";
import ExampleImage from "./example.webp";

export const Pricing = () => {
  return (
    <Section id="pricing" style={styles.container}>
      <Safe>
        <View style={[cssClass("pricing-rows"), styles.rows]}>
          <View style={[cssClass("pricing-row"), styles.row]}>
            <Title dark>Реклама в салоне</Title>
            <Text style={styles.subtitle}>
              Брендируем: специальный материал с ламинацией, высокое качество
              цветопередачи. Формат А4
            </Text>
            <Text style={styles.description}>
              Формат обеспечивает продолжительный контакт Вашей рекламы с
              потребителем в располагающей обстановке. За счет размещения в
              салоне автомобиля такси, Вы гарантировано получаете
              платежеспособную аудиторию.
            </Text>
            <View style={styles.items}>
              <View style={styles.item}>
                <Text style={styles.itemValue}>75 BYN</Text>
                <Text style={styles.itemDescription}>Размещение/месяц</Text>
              </View>
              <View style={styles.item}>
                <Text style={[styles.itemValue, styles.itemValueSecondary]}>
                  15 BYN
                </Text>
                <Text style={styles.itemDescription}>
                  Производство, монтаж и демонтаж
                </Text>
              </View>
            </View>
          </View>
          <View style={[cssClass("pricing-row"), styles.row, styles.rightSide]}>
            <View style={styles.imageContainer}>
              <Image
                style={styles.image}
                placeholder={ExampleImage}
                source={ExampleImage}
                alt="Интерьер автомобиля с черными сиденьями и подсветкой, на задних подголовниках прикреплены рекламные носители формата A4 с ярко-розовым градиентом и логотипом INCAR MEDIA на центральном экране"
              />
            </View>
            <LinkButton to="contacts">Заказать размещение</LinkButton>
          </View>
        </View>
      </Safe>
    </Section>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: darkColor,
  },
  rows: {
    flexDirection: "row",
    gap: 64,
  },
  row: {
    flex: 1,
    gap: 16,
  },
  subtitle: {
    color: "white",
    fontSize: 20,
    fontWeight: "500",
  },
  description: {
    color: secondaryColor,
    fontSize: 16,
  },
  items: {
    flexDirection: "row",
    gap: 32,
    marginTop: 16,
  },
  item: {
    flex: 1,
  },
  itemValue: {
    color: "white",
    fontSize: 32,
    fontWeight: "600",
  },
  itemValueSecondary: {
    color: secondaryColor,
  },
  itemDescription: {
    color: "white",
    fontSize: 16,
    fontWeight: "300",
  },

  rightSide: {
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
  },
  imageContainer: {
    width: "100%",
    maxWidth: 600,
    aspectRatio: 1000 / 563,
    borderRadius: 8,
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
  },
});
